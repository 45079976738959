import React, { useEffect } from 'react';
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"
import TopB2B from "../../assets/images/logo/B2B_Companies_2021_Badge.png"
import TopWeb from "../../assets/images/logo/Web_Developers_2021_Badge.png"
import logo2 from "../../assets/images/svg/logo-1-white.svg"
import appScreen from "../../assets/images/Animated_iPhone.gif"
import phoneFrame from "../../assets/images/Phone-Frame.png"



function Header(){


    return (
    <section className="service-section  inverted overflow-hidden">
    <div className=" container pt-5">
      <div className="row justify-content-center align-items-center justify-content-lg-between ">
        <div className=" col-lg-12 col-xl-12 text-white order-lg-1 mb-5 mb-lg-0 service-top">
          <span className="badge bg-muted-purple text-white rounded-pill mb-2">
            All Services
          </span>
          <h1 className="fs-1">
          Leveraging The Best <span className='text-green'>Technology</span>
          </h1>
           <p>Discover diverse services and solutions that will transform your business needs, keeping you ahead in today's technical landscape.</p>
          <a href="/contact/" className='button-link'>Unsure? Schedule Consultation
          <div className="arrow_movement">
              <img src={BtnArrow} alt=''/>
              <img src={BtnArrowbottom} alt=''/>
        </div>

          </a>
        </div>
       
      </div>
    </div>
  </section>
    )
}


export default Header;
