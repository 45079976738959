import React, { useEffect } from 'react';
import { tns } from 'tiny-slider/src/tiny-slider';
import pbs from "../assets/images/logo/PBS_Logo.png"
import nj from "../assets/images/logo/NJ_Department_Of_State.png"
import jetty from "../assets/images/logo/Jetty_Productions.png"
import ocean from "../assets/images/logo/Ocean_AC_Logo.png"
import topgolf from "../assets/images/logo/Topgolf_Logo.png"
import scla from "../assets/images/logo/The_SCLA_Logo.png"
import gsff from "../assets/images/logo/GSFF_Logo.png"

import { StaticImage } from "gatsby-plugin-image"

function LogoSlider({ logos, paddingTop }) {
  useEffect(() => {
    const carousel = document.querySelectorAll('[data-carousel]');

    carousel.forEach(function (element) {
      const settings = JSON.parse(element.dataset.carousel);

      settings.container = element;

      tns(settings);
    });

    // console.log(logos)

  }, []);

  return (
    <>
      {/* Logo List */}
      <section className={`pt-${paddingTop} slider-logo pb-5`} data-aos="fade-in">
        <div className="">
          <h6 className='fs-base logo-title'>Companies We’ve Impacted</h6>
          <div className="carousel carousel-align text-center">
            <div className="mb-1 blockquote-footer">
              <span>
                <span className="text-light">
                  {/* <b>Solutions Trusted &amp; Used By:</b> */}
                </span>
              </span>
            </div>
            <div data-carousel='{"startIndex": 0, "gutter": 48, "autoplayTimeout": 0, "speed": 5500, "rtl":true, "autoplay": true, "autoplayButton": false,"preventActionWhenRunning":true, "autoplayButtonOutput":false, "mouseDrag": true, "loop": true, "nav": false, "controls": false, "responsive": {"0": {"items": 3}, "768": {"items": 4}, "1200": {"items": 8}}}'>
              {logos.map((logo, i) => {
                return (<div>
                  <img
                    style={{ maxHeight: logo.maxHeight, width: "auto" }}
                    src={logo.logo}
                    alt={`${logo.alt}`}
                    className="logo lozad no-select"
                  />
                </div>)
              })}
            </div>
            <div data-carousel='{"startIndex": 18, "gutter": 48, "autoplayDirection": "backward", "autoplayTimeout": 0, "speed": 5500, "autoplay": true, "autoplayButton": false,"preventActionWhenRunning":true, "autoplayButtonOutput":false, "mouseDrag": true, "loop": true, "nav": false, "controls": false,  "responsive": {"0": {"items": 3}, "768": {"items": 4}, "1200": {"items": 8}}}'>
              {logos.map((logo, i) => {
                return (<div>
                  <img
                    style={{ maxHeight: logo.maxHeight, width: "auto" }}
                    src={logo.logo}
                    alt={`${logo.alt}`}
                    className="logo lozad no-select"
                  />
                </div>)
              })}
            </div>
          </div>
        </div>
      </section>
    </>

  );
}

export default LogoSlider;