const data = {
    home: {
        keywords: `
            Enterprise software solutions, Custom enterprise applications, 
            High-performance cloud infrastructure, Scalable business automation,
            Digital transformation consulting, AI-driven business solutions,
            Blockchain integration services, IoT enterprise solutions,
            Premium web development services, Robust cybersecurity solutions,
            Enterprise mobile app development, Big data analytics services,
            Corporate cloud migration, Jersey Shore tech innovations,
            New Jersey's leading tech consultancy, Atlantic City enterprise software,
            South Jersey digital transformation experts, North Jersey IT consulting,
            Tri-state area technology partners, Fortune 500 tech solutions NJ,
            Government-grade cybersecurity NJ, HIPAA-compliant software solutions,
            Financial sector IT consulting NJ, Healthcare technology innovations NJ
        `
    },
    about: {
        keywords: `
            Top-tier technology firm New Jersey, Industry-leading software developers,
            Award-winning IT consultancy, Innovative tech solutions provider,
            Experienced enterprise software team, Cutting-edge technology experts,
            Trusted technology partner for corporations, Strategic IT consulting services,
            Proven track record in large-scale projects, Tailored enterprise solutions,
            Differnt Systems excellence, F&S Digital legacy and innovation,
            New Jersey's premier tech company, Atlantic City's tech innovators,
            South Jersey's corporate IT partners, Mid-Atlantic region tech leaders
        `
    },
    services: {
        keywords: `
            Enterprise-grade web applications, Scalable cloud architecture design,
            Custom CRM development, ERP system integration,
            AI and machine learning solutions, Blockchain development services,
            Big data analytics platforms, IoT ecosystem development,
            High-volume eCommerce solutions, Microservices architecture,
            DevOps implementation, Containerization and orchestration,
            Serverless computing solutions, Edge computing services,
            5G-ready application development, Quantum computing consulting,
            Robotic Process Automation (RPA), Augmented Reality for enterprises,
            Virtual Reality business solutions, Cybersecurity for corporations,
            Data privacy compliance solutions, Cloud security services
        `
    },
    contact: {
        keywords: `
            Enterprise IT consultation, Corporate technology assessment,
            Digital transformation strategy, IT infrastructure planning,
            Technology roadmap development, CTO advisory services,
            IT budget optimization, Technology vendor management,
            Enterprise architecture consulting, IT governance services,
            New Jersey technology partnership, Atlantic City IT consulting,
            Philadelphia metro area tech services, New York tri-state area IT solutions
        `
    },
    faq: {
        keywords: `
            Enterprise software development cost, Corporate IT project timeline,
            ROI of digital transformation, Cybersecurity implementation process,
            Cloud migration strategies for large businesses, AI integration benefits,
            Blockchain adoption challenges, IoT implementation for corporations,
            Custom software vs off-the-shelf solutions, Data analytics ROI for enterprises,
            Scalability of custom applications, Long-term IT partnership benefits,
            Enterprise-level support services, Technology stack for large-scale projects,
            Compliance and regulatory tech solutions, Modernizing legacy systems cost
        `
    },
}

export default data