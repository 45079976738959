import React, { useState } from "react";
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"
import SEO from "../SEO"
import Layout from "../../components/Layout"
import keywords from "../../constants/keywords"

const arrayOfCategory = [
  {
    category: "software",
    name: "Websites",
    des: "Lightning-fast sites with a technical foundation prioritizing conversions, and that integrate features your users deserve.",
    link: "/software-development-services/#websites",
  },
  {
    category: "software",
    name: "Mobile Applications",
    des: "Empower your vision with seamless connectivity, sensor integration, and enhanced on-the-go user experiences.",
    link: "/software-development-services/#mobile-apps",
  },
  {
    category: "software",
    name: "Web Applications",
    des: "Applications accessible across web browsers on any device, enhancing the experience without the need for hardware upgrades.",
    link: "/software-development-services/#web-apps",
  },
  {
    category: "software",
    name: "Middleware Scripts",
    des: "Automate manual recurring tasks by connecting and syncing multiple existing systems seamlessly utilizing APIs, Web Crawling, or Scraping.",
    link: "/software-development-services/#middleware",
  },
  {
    category: "software",
    name: "E-Commerce Websites",
    des: "Customize every aspect of the online shopping experience to leave a lasting impression and boost conversions.",
    link: "/software-development-services/#ecommerce",
  },
  {
    category: "software",
    name: "Integration Services",
    des: "Customize your ERP, CRM, PSA, HMS, RPA, PMS, or other system(s) to precisely meet your business needs.",
    link: "/software-development-services/#integrations",
  },
  {
    category: "automation",
    name: "Kiosk Stations",
    des: "Add or Exchange your legacy or subscription-based kiosks with a comprehensive, cloud or locally managed alternative.",
    link: "/automation-services/#kiosks",
  },
  {
    category: "automation",
    name: "Digital Signage",
    des: "Leverage internal marketing initiatives and data to craft a next-generation system without the per-display subscription costs.",
    link: "/automation-services/#signage",
  },
  {
    category: "automation",
    name: "Robotics",
    des: "Cost-effectively boost existing labor productivity, while enhancing your overall client satisfaction.",
    link: "/automation-services/#robotics",
  },
  {
    category: "automation",
    name: "POS/Cashless RFID",
    des: "Maximize features and integrations tailored to meet your organization's evolving demands, whether with new or existing systems.",
    link: "/automation-services/#pos",
  },
  {
    category: "automation",
    name: "Room Automation",
    des: "Integrate technology and processes to automatically control and perform tasks with limited human intervention.",
    link: "/automation-services/#room-automation",
  },
  {
    category: "automation",
    name: "IoT Development",
    des: "Dream to expand current system capabilities or create fully customized solutions, down to the processor and PCB.",
    link: "/automation-services/#development",
  },
  {
    category: "cloud_security",
    name: "Cloud Storage",
    des: "Securely store, encrypt, and replicate your data globally.",
    link: "/cloud-services/#storage",
  },
  {
    category: "cloud_security",
    name: "WordPress Hosting",
    des: "Optimized WordPress hosting for high-traffic sites, with automatic cyber threat mitigation.",
    link: "/cloud-services/#wordpress-hosting",
  },
  {
    category: "cloud_security",
    name: "Content Acceleration",
    des: "Transfer data in milliseconds, reducing server usage and increasing user satisfaction.",
    link: "/cloud-services/#content-acceleration",
  },
  {
    category: "cloud_security",
    name: "Media Streaming",
    des: "Stream DRM-protected audio and video with adaptive quality, HDR, and up to 8K UHD.",
    link: "/cloud-services/#streaming",
  },
  {
    category: "cloud_security",
    name: "Managed Cybersecurity",
    des: "Adaptive protection that swiftly pinpoints and mitigates cyber threats.",
    link: "/cloud-services/#cybersecurity",
  },
  {
    category: "cloud_security",
    name: "Managed Cloud Hosting",
    des: "Simplified hosting for complex applications, architected by growth-focused engineers.",
    link: "/cloud-services/#managed-cloud",
  },
  {
    category: "cloud_security",
    name: "Maintenance Plans",
    des: "Content, cyber, dev, design, and transcreation experts for ongoing engagement.",
    link: "/cloud-services/#maintenance",
  },
  {
    category: "new_signals",
    name: "Advanced AI/ML",
    des: "Enable advanced features through user behavior learning or pre-trained models. Utilize the latest in computer vision and natural language processing from publicly available, open-source, or custom sources.",
    link: "/new-signals-services/#ai-ml",
  },
  {
    category: "new_signals",
    name: "Spatial Computing (AR, VR, XR)",
    des: "Crafting new experiences with application support, including remote collaboration, training, education, and product design visualization.",
    link: "/new-signals-services/#spatial-computing",
  },
];

function ServiceTabbing() {
  const [category, setCategory] = useState("software");

  const handleButtonClick = (name) => {
    setCategory(name);
  };

  // console.log("arrayOfCategory :>> ", arrayOfCategory);

  const arrayFilter = arrayOfCategory?.filter(
    (val) => val.category === category
  );

  // console.log("arrayFilter :>> ", arrayFilter);

  return (
    <div className="tabbing-section">
      <div className="wrapper">
        <div className="button-wrapper" style={{ marginBottom: "50px" }}>
          {/* <button
            className="button"
            onClick={() => handleButtonClick("consulting")}
            style={{ background: category === "consulting" ? "#00C4A9" : "" }}
          >
            Consulting
          </button> */}
          <button
            className="button"
            onClick={() => handleButtonClick("software")}
            style={{ background: category === "software" ? "rgba(48, 13, 129, 0.5)" : "" }}
          >
            Software
          </button>
          <button
            className="button"
            onClick={() => handleButtonClick("automation")}
            style={{ background: category === "automation" ? "rgba(48, 13, 129, 0.5)" : "" }}
          >
            Automation
          </button>
          <button
            className="button"
            onClick={() => handleButtonClick("cloud_security")}
            style={{ background: category === "cloud_security" ? "rgba(48, 13, 129, 0.5)" : "" }}
          >
            Cloud & Cybersecurity
          </button>
          <button
            className="button"
            onClick={() => handleButtonClick("new_signals")}
            style={{ background: category === "new_signals" ? "rgba(48, 13, 129, 0.5)" : "" }}
          >
            New Signals
          </button>
        </div>

        <div className="swiper-container">
          <div className="container">
            <div className="row">
              {arrayFilter.map((val, index) => (
                <div className="col-md-4">
                  <div className="tab_box">
                    <h5>{val?.name}</h5>
                    <p>{val?.des}</p>
                    <a href={val?.link} className='button-link'>Explore Service
                      <div className="arrow_movement">
                        <img src={BtnArrow} alt='' />
                        <img src={BtnArrowbottom} alt='' />
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceTabbing;