import React, { useEffect } from 'react'
import { useLocation } from "@reach/router"
// import websiteImage from '../assets/images/ServicesImages/website-on-laptop.jpg'
// import webappsImage from '../assets/images/ServicesImages/tablet-with-dashboard-metrics.jpg'
// import mobileappsImage from '../assets/images/ServicesImages/person-holding-phone-with-app.jpg'
import danImage from '../../assets/images/ServicesImages/Dan-Using-Touchscreen-To-Write.jpg'
import { Helmet } from 'react-helmet';
import { StaticImage } from "gatsby-plugin-image"
// import appScreen from "../assets/images/Animated_iPhone.gif"
import phoneFrame from "../../assets/images/Phone-Frame.png"
import BigPicture from "bigpicture";
import LogoSlider from "../LogoSlider.js"
import WebsiteLogos from "../../constants/websiteLogos.js"



import Image00 from '../../assets/images/serviceCards/00.jpg'
import Image01 from '../../assets/images/serviceCards/10.jpg'
import Image02 from '../../assets/images/serviceCards/01.jpg'
import Image03 from '../../assets/images/serviceCards/11.jpg'
import Image04 from '../../assets/images/serviceCards/02.jpg'
import Image05 from '../../assets/images/serviceCards/12.jpg'



function ServicesCards(props) {

    // Allow for external toggling of services
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const toggle = params.get('toggle');
        if (toggle) {
          const toggleEl = document.querySelector(`[data-toggle-id="${toggle}"]`);
          if (toggleEl) {
            toggleEl.click();
          }
        }
      }, []);

      // Scroll down to services area for external toggling links
        const location = useLocation();

      useEffect(() => {
        const params = new URLSearchParams(location.search);
        const toggleParam = params.get('toggle');
        const targetParam = location.hash.slice(1);
    
        if (toggleParam) {
          const toggleEl = document.querySelector(`[data-toggle-id="${toggleParam}"]`);
          if (toggleEl) {
            toggleEl.click();
          }
        }
    
        if (targetParam === 'ServicesArea') {
          const target = document.getElementById(targetParam);
          if (target) {
            target.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [location]);    

    useEffect(() => {
        const bigPictureElements = document.querySelectorAll("[data-bp]");

        bigPictureElements.forEach((bigPictureElement) => {
            const elementOptions = JSON.parse(bigPictureElement.dataset.bp);

            bigPictureElement.addEventListener("click", (e) => {
                let defaultOptions = {
                    el: e.target,
                    noLoader: true,
                };

                if (elementOptions.parentGalleryClass) {
                    const galleryImages = document.querySelectorAll(
                        `.${elementOptions.parentGalleryClass} [data-bp]`
                    );

                    let gallery = [];
                    galleryImages.forEach((image) => {
                        const imageOptions = JSON.parse(image.dataset.bp);
                        const src = imageOptions.imgSrc;
                        gallery.push({ src });
                    });

                    const position = gallery.findIndex(
                        (item) => item.src === elementOptions.imgSrc
                    );

                    defaultOptions.gallery = gallery;
                    defaultOptions.position = position;
                }

                BigPicture({
                    ...defaultOptions,
                    ...elementOptions,
                    dimensions: [1920, 1080],

                });
            });
        });

    })


    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="../assets/css/font-awesome-all.min.css"
                    integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            </Helmet>

            {/* Service Options */}
            <section className="bg-black inverted pt-5 pt-xl-20 pt-xs-10 pb-2" >
                <div className="container">
                    {/* <div className="d-none d-lg-block row mb-10">
                        <div className="col">
                        </div>
                    </div> */}
                    <div className="row g-3 g-xl-5 justify-content-between" id="ServicesArea">
                        <div className="services-column-container col-xl-4">
                            <div className='sticky sticky-top' style={{ top: '4rem', position: 'sticky', display: 'flex', justifyContent: 'center' }}>
                                {/* <div className="d-block d-xl-none services-menu-container example-one">
                                    <div className="header example-one-header scroll">
                                        <div className="nav vertical-align-middle">

                                            <a
                                                className="active"
                                                id="component-1-1-tab-mobile"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-1"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-1"
                                                aria-selected="true"
                                            ><span className="services-item">Websites</span></a>

                                            <a
                                                id="component-1-3-tab-mobile"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-3"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-3"
                                                aria-selected="false"
                                            ><span className="services-item">Web Apps</span></a>

                                            <a
                                                id="component-1-2-tab-mobile"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-2"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-2"
                                                aria-selected="false"
                                            ><span className="services-item">Mobile Apps</span></a>

                                            <a
                                                id="component-1-4-tab-mobile"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-4"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-4"
                                                aria-selected="false"
                                            ><span className="services-item">Cloud Hosting</span></a>

                                            <a
                                                id="component-1-5-tab-mobile"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-5"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-5"
                                                aria-selected="false"
                                            ><span className="services-item">Custom Solution</span></a>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="services-menu-container example-one">
                                    <div className="header example-one-header scroll">
                                        <div className="nav nav-tabs flex-xl-column vertical-align-middle">

                                            {/* <ul
                                            className="nav nav-tabs flex-xl-column "
                                            id="component-1"
                                            role="tablist"
                                        > */}
                                            {/* <li className="  nav-item"> */}
                                            <span
                                                className="active services-item nav-item  nav-link fs-5"
                                                id="component-1-1-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-1"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-1"
                                                aria-selected="false"
                                                data-toggle-id="Websites"
                                            >
                                                Websites
                                            </span>
                                            {/* </li> */}
                                            {/* <li className="nav-item"> */}
                                            <span
                                                className="services-item nav-item  nav-link fs-5"
                                                id="component-1-3-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-3"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-3"
                                                aria-selected="false"
                                                data-toggle-id="WebApps"
                                            >
                                                Web Apps
                                            </span>
                                            {/* </li> */}
                                            {/* <li className=" nav-item"> */}
                                            <span
                                                className="services-item nav-item nav-link fs-5"
                                                id="component-1-2-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-2"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-2"
                                                aria-selected="false"
                                                data-toggle-id="MobileApps"
                                            >
                                                Mobile Apps
                                            </span>
                                            {/* </li> */}
                                            {/* <li className=" nav-item"> */}
                                            <span
                                                className="services-item nav-item nav-link fs-5"
                                                id="component-1-4-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-4"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-4"
                                                aria-selected="false"
                                                data-toggle-id="CloudServices"
                                            >
                                                Cloud Services
                                            </span>
                                            {/* <span
                                                className="services-item nav-item nav-link fs-5"
                                                id="component-1-4-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-4"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-4"
                                                aria-selected="false"
                                            >
                                                Technology Research
                                            </span> */}
                                            {/* </li> */}
                                            {/* <li className="nav-item"> */}
                                            <span
                                                className="services-item nav-item nav-link fs-5"
                                                id="component-1-5-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#component-1-5"
                                                type="button"
                                                role="tab"
                                                aria-controls="component-1-5"
                                                aria-selected="false"
                                                data-toggle-id="HardwareIntegrations"
                                            >
                                                Hardware Integrations
                                            </span>
                                            {/* </li> */}
                                            {/* </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="tab-content" id="component-1-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="component-1-1"
                                    role="tabpanel"
                                    aria-labelledby="component-1-1-tab"
                                >
                                    <div className="row g-3 g-xl-3">
                                        <div className='pt-0 pb-0 mt-0 mb-2'><h2><span className='text-green fs-3'>Websites</span></h2></div>
                                        <div className='pt-0 pb-0 mt-1 mb-0'><p>To us, blazing fast websites are the standard, not the goal. Every site is built with performance in mind, whether it’s a simple blog or a large e-commerce store. Load the content that matters most to users in milliseconds, own SEO rankings, and focus on conversions. Our core competency centers around creating a cohesive experience blending high-design with integrations and features that elevate experiences.</p></div>
                                        <a href="/contact/">
                                            <p>
                                                <u>Speak With An Expert</u>{" "}
                                                <i className=" mt-1 mb-5 bi bi-arrow-up-right" />
                                            </p>
                                        </a>
                                        <LogoSlider logos={WebsiteLogos} />

                                        <div className='pt-0 pb-0 mt-0 mb-0'><h3><span className='fs-5 text-secondary'>Trending Features:</span></h3></div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image00})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-1.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer mt-auto pt-0">
                                                        <h4 className="card-title">E-Commerce</h4>
                                                        <p className="text-secondary">
                                                            Offer delivery, pickup, and online shopping solutions to your customers.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image01})`, backgroundSize: 'cover' }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-2.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">API Integrations</h4>
                                                        <p className="text-secondary">
                                                            Integrate new or existing systems into your site for a seamless experience.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image02})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-3.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Infinite Scalability</h4>
                                                        <p className="text-secondary">
                                                            Utilize cutting-edge technologies that ensure critical 24/7/365 uptime.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image03})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Responsiveness</h4>
                                                        <p className="text-secondary">
                                                            Guarantee every device can access and navigate your website with ease.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image04})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">High-Ranking SEO</h4>
                                                        <p className="text-secondary">
                                                            Ensure that potential customers can discover your business online.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="component-1-2"
                                    role="tabpanel"
                                    aria-labelledby="component-1-2-tab"
                                >
                                    <div className="row g-3 g-xl-3">
                                        <div className='pt-0 pb-0 mt-0 mb-2'><h2><span className='text-green fs-3'>Mobile Applications</span></h2></div>
                                        <div className='pt-0 pb-0 mt-1 mb-0'><p>Convert your vision into a universal experience for phones, tablets, and smart devices. One codebase to conquer it all, or native code for finite control & optimization— we’re experts at it all. We’ll work alongside you to ensure we choose the right language and framework that meets your security, device-access level, capability, and integration goals. Connect your app to the cloud for increased capabilities, or to empower your team to push updates with ease. Whether your vision is clearly defined or just scratching the surface, we’re your resource for success.</p></div>
                                        <a href="/contact/">
                                            <p>
                                                <u>Speak With An Expert</u>{" "}
                                                <i className=" mt-1 mb-5 bi bi-arrow-up-right" />
                                            </p>
                                        </a>
                                        <div className='pt-0 pb-0 mt-0 mb-0'><h3><span className='fs-5 text-secondary'>Trending Features:</span></h3></div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image00})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-1.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer mt-auto pt-0">
                                                        <h4 className="card-title">Sensor Access</h4>
                                                        <p className="text-secondary">
                                                            Link on-device gyroscope, accelerometer, and barometer sensor data to your app.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image01})`, backgroundSize: 'cover' }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-2.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">CMS Integration</h4>
                                                        <p className="text-secondary">
                                                            Easily manage your app's content from the cloud without writing a single line of code.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                               
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image02})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-3.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Media Streaming</h4>
                                                        <p className="text-secondary">
                                                            Stream DRM-protected audio and video with adaptive quality, HDR, and up to 4K.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image03})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Location Services</h4>
                                                        <p className="text-secondary">
                                                            Identify, visualize, and calculate user location using GPS, Bluetooth, and WiFi.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image04})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Machine Learning</h4>
                                                        <p className="text-secondary">
                                                            Enable advanced features by learning user behavior or utilizing pretrained models.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                               
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image05})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">E-Commerce</h4>
                                                        <p className="text-secondary">
                                                            Offer delivery, pickup, and online shopping solutions to your customers.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="component-1-3"
                                    role="tabpanel"
                                    aria-labelledby="component-1-3-tab"
                                >
                                    <div className="row g-3 g-xl-3">
                                        <div className='pt-0 pb-0 mt-0 mb-2'><h2><span className='text-green fs-3'>Web Applications</span></h2></div>
                                        <div className='pt-0 pb-0 mt-1 mb-0'><p>Unlock limitless potential from your existing devices, no matter the operating system or configuration— simply through a web browser. Enable real-time collaboration, high-level encryption, or advanced AI automation; the only limitation is the size of your imagination. No per-user licensing or predatory contracts. Own your code to keep it as an in-house advantage, or release it to the public as the next big platform.</p></div>
                                        <a href="/contact/">
                                            <p>
                                                <u>Speak With An Expert</u>{" "}
                                                <i className=" mt-1 mb-5 bi bi-arrow-up-right" />
                                            </p>
                                        </a>
                                        <div className='pt-0 pb-0 mt-0 mb-0'><h3><span className='fs-5 text-secondary'>Trending Features:</span></h3></div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image00})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-1.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer mt-auto pt-0">
                                                        <h4 className="card-title">Analytics</h4>
                                                        <p className="text-secondary">
                                                            Sort, display, analyze and customize metric reports from a single dashboard.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image01})`, backgroundSize: 'cover' }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-2.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Data Collection</h4>
                                                        <p className="text-secondary">
                                                            Gather, upload, encrypt & store information and files to access remotely.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image02})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-3.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Media Streaming</h4>
                                                        <p className="text-secondary">
                                                            Stream DRM-protected audio and video with adaptive quality, HDR, and up to 4K.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image03})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">API Integrations</h4>
                                                        <p className="text-secondary">
                                                            Integrate new or existing systems into your site for a seamless experience.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image04})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Social Networking</h4>
                                                        <p className="text-secondary">
                                                            Create your own public or private forum for connection and collaboration.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="component-1-4"
                                    role="tabpanel"
                                    aria-labelledby="component-1-4-tab"
                                >
                                    <div className="row g-3 g-xl-3">
                                        <div className='pt-0 pb-0 mt-0 mb-2'><h2><span className='text-green fs-3'>Cloud Services</span></h2></div>
                                        <div className='pt-0 pb-0 mt-1 mb-0'><p>Utilizing the power of the cloud, your creation is always available, always backed up, and always ready to scale alongside you. With F&S, you’ll be free from managing infrastructure, configuring security, monitoring vulnerabilities, and dealing with vendor relations. From multi-cloud solutions for critical uptime, to scalable configuration options to absorb spikes in traffic— we’ve got the partnerships, engineers, and experience to stand by our 100% uptime guarantee.</p></div>
                                        <a href="/contact/">
                                            <p>
                                                <u>Speak With An Expert</u>{" "}
                                                <i className=" mt-1 mb-5 bi bi-arrow-up-right" />
                                            </p>
                                        </a>
                                        <div className='pt-0 pb-0 mt-0 mb-0'><h3><span className='fs-5 text-secondary'>Trending Features:</span></h3></div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image00})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-1.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer mt-auto pt-0">
                                                        <h4 className="card-title">Auto-Scaling</h4>
                                                        <p className="text-secondary">
                                                            Absorb abnormal amounts of traffic seamlessly without large server costs.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                               
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image01})`, backgroundSize: 'cover' }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-2.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Content Accleration</h4>
                                                        <p className="text-secondary">
                                                            Transfer data in milliseconds, reduce server usage, and instantly improve user experience.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image02})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-3.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Media Streaming</h4>
                                                        <p className="text-secondary">
                                                            Stream DRM-protected audio and video with adaptive quality, HDR, and up to 4K.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image03})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">DDoS/Bot Protection</h4>
                                                        <p className="text-secondary">
                                                            Enterprise application and network based security to adapt and overcome the latest threats.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image04})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Storage Backups</h4>
                                                        <p className="text-secondary">
                                                            Encrypt & replicate your data across the globe to conquer even the most extreme disasters.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="component-1-5"
                                    role="tabpanel"
                                    aria-labelledby="component-1-5-tab"
                                >
                                    <div className="row g-3 g-xl-3">
                                        <div className='pt-0 pb-0 mt-0 mb-2'><h2><span className='text-green fs-3'>Hardware Integrations</span></h2></div>
                                        <div className='pt-0 pb-0 mt-1 mb-0'><p>We're experts at architecting, procuring, managing, and integrating hardware with software— from room automation systems, to kiosks and digital signage solutions. Physical installation requirements are executed seamlessly through our comprehensive coordination with in-house teams or contractors. Avoid vendor-lock by having our team train and certify yours, enabling them to support and maintain the solution. If issues arise, our 365 support has you covered.</p></div>
                                        <a href="/contact/">
                                            <p>
                                                <u>Speak With An Expert</u>{" "}
                                                <i className=" mt-1 mb-5 bi bi-arrow-up-right" />
                                            </p>
                                        </a>
                                        <div className='pt-0 pb-0 mt-0 mb-0'><h3><span className='fs-5 text-secondary'>Trending Features:</span></h3></div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image00})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-1.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer mt-auto pt-0">
                                                        <h4 className="card-title">Physical Installs</h4>
                                                        <p className="text-secondary">
                                                            Whether a temporary or permanent installation, we ensure a hands-off project experience.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image01})`, backgroundSize: 'cover' }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-2.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Hardware + Software</h4>
                                                        <p className="text-secondary">
                                                            Customize software to fit existing hardware, or collaborate with us from project conception.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6" data-aos="fade-up">
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image02})`, backgroundSize: 'cover', }}
                                            >
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-3.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Kiosks</h4>
                                                        <p className="text-secondary">
                                                            Replace legacy or subscription-based kiosks with a fully cloud or locally managed solution.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image03})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">Digital Signage</h4>
                                                        <p className="text-secondary">
                                                            Fully customize your signage to incorporate smart content and features for your audience.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="col-md-6"
                                            data-aos="fade-up"
                                            data-aos-delay={100}
                                        >
                                            <a
                                                
                                                className="card card-hover-border" style={{ backgroundImage: `url(${Image04})`, backgroundSize: 'cover', }}
                                            >
                                                {/* equal-md-1-1 */}
                                                <div className="card-wrap">
                                                    <div className="card-header pb-0">
                                                        {/* <img
                                                            src="./assets/images/logo/logo-vendor-4.svg"
                                                            alt="Logo"
                                                            className="mb-4 w-20"
                                                        /> */}
                                                    </div>
                                                    <div className="card-footer pt-0 mt-auto">
                                                        <h4 className="card-title">AI/ML</h4>
                                                        <p className="text-secondary">
                                                            Incorporate existing or launch research into the most intricate details of behavior or information.
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default ServicesCards;