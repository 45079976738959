const data = [
    {
        logo: '../../logo/Topgolf_Logo.png',
        maxHeight: 25,
        alt:"Topgolf Logo"
    },
    
    {
        logo: "../../logo/NJ_Department_Of_State.png",
        maxHeight: 50,
        alt:"NJ State Logo"
    },
    {
        logo: "../../logo/Ocean_AC_Logo.png",
        maxHeight: 50,
        alt:"Ocean AC Logo"
    },
    {
        logo: "../../logo/PBS_Logo.png",
        maxHeight: 40,
        alt:"PBS Logo"
    },
    {
        logo: "../../logo/Jetty_Productions.png",
        maxHeight: 35,
        alt:"Jetty Logo"
    },
    {
        logo: "../../logo/GSFF_Logo.png",
        maxHeight: 55,
        alt:"GSFF Logo"
    },
    {
        logo: "../../logo/The_SCLA_Logo.png",
        maxHeight: 35,
        alt:"SCLA Logo"
    },
]

export default data 