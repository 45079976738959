import React, { useEffect } from "react"
import loadable from '@loadable/component'
import Plyr from "plyr"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Hero from "../components/headers/services-header"
import keywords from "../constants/keywords"

import Services from "../components/ServicesPage/ServicesCards";
import ServiceTabbing from "../components/ServicesPage/ServiceTabbing"


const LoadableParallax = loadable(() => import(/* webpackPrefetch: true */ '../components/parallax/servicesParallax'))

const ServicesPage = () => {

    useEffect(() => {

        const elements = document.querySelectorAll('video');

        elements.forEach(function (element) {
            new Plyr(element);
        });

    })


    return (
        <>
            <Layout black={false}>
                <SEO
                    title={"Our Services | Differnt Systems"}
                    description={"Software. Automation. Cloud. From research to deployment, our expert team collaborates with you to architect, design, develop, test, and launch."}
                    customKeywords={keywords.services.keywords}
                />

                <div class="offcanvas-wrap">
                    <Hero />
                    {/* <Hero background={heroImage} title="What We Do Best" subtitle="Schedule a Free Consultation" link="/contact/" badge="Our Services" /> */}
                  
                    <ServiceTabbing />
                    {/* cta */}
                   
                </div>
            </Layout>
        </>
    )
}

export default ServicesPage
